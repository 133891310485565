<template>
  <div class="guide-container">
    <h1>How To Create A Binance Account In 2023: Step By Step Guide</h1>

    <img
      src="@/assets/imgs/Companies/Binance/binance_logo_black_and_yellow.webp"
      alt="binance exchange black on yellow logo"
    />

    <br />
    <h2 class="second-colour">
      What this beginners guide covers
    </h2>

    <ul>
      <li>
        <h3>
          Registering and verifiying an account using the website.
        </h3>
      </li>
      <li>
        <h3>
          Indentity vertification using the Binance app and qr code.
        </h3>
      </li>
      <li>
        <h3>
          KYC (Know Your Customer) steps.
        </h3>
      </li>
    </ul>

    <p class="second-colour-background padding-1 third-colour">
      By the end of this guide you will have your Binance account set up and
      ready to go. You'll then be able to move onto funding your account with
      our next guide linked at the bottom.
    </p>

    <br />
    <h2 class="second-colour">
      What is required for a Binance account?
    </h2>

    <p>
      For Binance account registration, you must complete the verification
      process, also known as KYC ("know your customer"). The specific
      credentials needed for KYC by Binance will differ depending on the account
      type and the region you are in.
    </p>
    <p>
      If you reside in the United States, any of the following documents will
      fulfill Binance's KYC requirements:
    </p>
    <ul>
      <li>A valid passport.</li>
      <li>Government-issued ID.</li>
      <li>Driver's license.</li>
      <li>PAN card or your social security number.</li>
      <li>Your voter's ID.</li>
    </ul>

    <p>
      The example below registers a UK account using a British passport. However
      the flow is similar in other countries.
    </p>

    <br />
    <h2 class="second-colour">
      How long does it take to open a binance account?
    </h2>
    <p>
      Once the documentation is uploaded correctly, it can take as little as a
      few minutes or as long as 15 minutes for individual accounts to be
      approved. Binance allows a maximum of 3 days for completing ID
      verification.
    </p>

    <br />
    <h2 class="second-colour">
      What are the fees and minimum deposit to open an account?
    </h2>
    <p>
      There are no fees or a minimum deposit to simply open a Binance account.
      Fees are only incurred when trading and the minumum deposit depends on
      your region.
    </p>

    <br />
    <h2 class="second-colour">
      Steps to open a Binance account on Desktop and Mobile
    </h2>

    <p @click="openBinanceRegisterPage">
      1) Navigate to Binance register page by <u> clicking here</u>.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/1.jpg"
      alt="binance home page"
    />

    <p>2) Click 'Sign up with phone or email'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/2.jpg"
      alt="sign up with phone or email page"
    />

    <p>
      3) Enter your email, a password, agree to the terms and condtions. And
      finally click on 'Create personal account'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/3.jpg"
      alt="create a personal account page"
    />

    <p>
      4) You will receive a code to the email you registered. Open your email
      and enter the code into the box then click 'Submit'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/4.jpg"
      alt="Email verification code page"
    />

    <p>5) Click the Log In button in the upper right</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/5.jpg"
      alt="Home screen pointing to login button"
    />

    <p>6) In the Log In screen enter your email address and click on Next</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/6.jpg"
      alt="Login screen with email or phone number"
    />

    <p>7) Enter your password and click Log In</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/7.jpg"
      alt="Enter password screen"
    />

    <p>8) Enter the code sent to your phone number or email if prompted</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/8.jpg"
      alt="Enter email or phone verification screen"
    />

    <p>9) Close the popup</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/9.jpg"
      alt="Welcome to binance modal popup"
    />

    <p>
      10) Click on the Verify button on the upper right followed by the Start
      Now button
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/10.jpg"
      alt="Personal verification screen with verify button"
    />

    <p>11) Fill in the verification details and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/11.jpg"
      alt="Enter nationality, name, date of birth modal popup"
    />

    <p>
      12) To verify yourself you'll need to download and install the Binance app
      on Android or iPhone. Click on the 'Download App Here' button and download
      from the Apple App Store or Google Play
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/12.jpg"
      alt="Download the Binance app popup woth QR code"
    />

    <p>13) Select to download for either iPhone or Android</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/13.jpg"
      alt="Download from Apple App Store or Google Play page"
    />

    <p>
      14) In the Apple App Store or Google Play Store, click download and then
      open
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/14.jpg"
      alt="Google Play Binance app page"
    />

    <p>15) Open the Binance app on your mobile and click 'Log In'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/15.jpg"
      alt="Binance Android app welcome screen"
    />

    <p>
      16) Enter the email you used to sign up to the Binance website and click
      'Next'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/16.jpg"
      alt="Android app email login sreen"
    />

    <p>17) Enter your password and click 'Log In'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/17.jpg"
      alt="Android app welcome back screen with password field"
    />

    <p>
      18) Enter the code sent to your phone, or the email verification code.
      Then click 'Submit'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/18.jpg"
      alt="Confirm new device login screen"
    />

    <p>19) Click on the scan icon on the upper right</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/19.jpg"
      alt="Click to scan QR code screen"
    />

    <p>20) Allow the Binance app to take pictures if prompted</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/20.jpg"
      alt="Allow Binaince app to take pictures permissions popup"
    />

    <p>21) Scan the QR code on the Binance website</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/21.jpg"
      alt="Binance website scan QR code verification page"
    />

    <p>
      22) The Binance app on your phone should open the 'Verify Your Identity'
      page. Click on 'Choose Document'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/22.jpg"
      alt="Binance app verify your identity choose document screen"
    />

    <p>23) Select your country and the document you would like to use as ID</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/23.jpg"
      alt="Choose your id document screen"
    />

    <p>
      24) The Binance app will open the camera view. Place your document within
      the view frame and click on the white button
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/24.jpg"
      alt="Android app take phone screen"
    />

    <p>25) If you're happy with the image, click on 'Submit photo'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/25.jpg"
      alt="Android app submit id phone screen"
    />

    <p>
      26) Next you'll be asked to record a short video following some simple
      instructions. These are actions such as 'look at the camera', 'look to
      your right' etc. Click on 'Record video'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/26.jpg"
      alt="Android app record a video prompt screen"
    />

    <p>27) Allow access to your devices microphone if prompted</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/27.jpg"
      alt="Android app enable microphone screen"
    />

    <p>28) Click on 'Allow' if the popup appears</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/28.jpg"
      alt="Android allow Binance to record audio popup"
    />

    <p>
      29) Once you've followed the instructions and finished recording the short
      video for identification. Click on 'Upload video'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/29.jpg"
      alt="Binance Android app upload video screen"
    />

    <p>
      30) Answer the questions about what you will use Binance for, then click
      on 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/30.jpg"
      alt="What will you use binance for question screen"
    />

    <p>31) Select your source of income and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/31.jpg"
      alt="Whats your source of income question screen"
    />

    <p>32) Select your employment status and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/32.jpg"
      alt="Whats your employment status question screen"
    />

    <p>33) Select how much you expect to trade and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/33.jpg"
      alt="How much do you expect to trade question screen"
    />

    <p>34) Select your industry of work and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/34.jpg"
      alt="Indicate your work industry question"
    />

    <p>35) Select your payment channel and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/35.jpg"
      alt="Which payment channel will you use question"
    />

    <p>36) Enter your address details and click 'Continue'</p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/36.jpg"
      alt="Proof of address and country select screen"
    />

    <p>
      37) Your proof of identification will now be under review. Click 'Go to
      Home'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/37.jpg"
      alt="Id under review notification screen"
    />

    <p>
      38) Once approved you will receie an email showing 'Verification
      Successful'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/38.jpg"
      alt="Binance verification successful email"
    />

    <p>
      39) Back on the Binance site click on the 'Continue' button if the popup
      is still there.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/39.jpg"
      alt="Binance website ID verification modal"
    />

    <p>
      40) Click on the profile icon in the upper right and select
      'Identification'. You should see your status as 'Completed'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToOpenAnAccount/40.jpg"
      alt="Binance ID verification screen completed"
    />
    <br />
    <br />
    <h2 class="second-colour">
      Congratulations
    </h2>
    <p>
      You've now successfully registered and validated your account with
      Binance.
    </p>

    <br />
    <br />
    <h2 class="second-colour">
      Next Steps
    </h2>

    <p>
      <router-link
        class="no-underline"
        to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
        ><u>Click here</u> to learn how to deposit money with a bank transfer
        and buy Bitcoin.</router-link
      >
    </p>

    <p>Or</p>

    <p>
      <router-link
        class="no-underline"
        to="/how-to-fund-and-buy-bitcoin-with-a-binance-account"
        ><u>Click here</u> on how to buy bitcoin on binance with debit card /
        credit card</router-link
      >
    </p>
  </div>
</template>

<script>
import { event } from 'vue-gtag';

export default {
  name: 'HowToOpenABinanceAccount',

  setup() {
    const openBinanceRegisterPage = () => {
      //Track event in Google Analytics:
      //https://matteo-gabriele.gitbook.io/vue-gtag/
      event('binance_registerAccountLinkClicked', {
        method: 'openBinanceRegisterPage'
      });

      //How to open link in a new tab:
      //https://javascript.plainenglish.io/vue-open-link-in-new-tab-6d4cbc009f0e
      window.open(
        'https://accounts.binance.com/en/register?ref=G6UDTO47',
        '_blank',
        'noreferrer'
      );
    };

    return {
      openBinanceRegisterPage
    };
  }
};
</script>
